export const onInitialClientRender = () => {
  let allImgsAreLoaded = () =>
    Array.from(document.getElementsByTagName("img")).every(e => e.complete)

  function onLoad(fn, fallback, retries = 100, interval = 30) {
    if (
      document.readyState === "complete" &&
      allImgsAreLoaded() &&
      document.fonts.ready
    ) {
      console.log("Loaded!")
      fn()
    } else {
      if (retries > 0)
        setTimeout(() => onLoad(fn, fallback, retries - 1, interval), interval)
      else if (fallback) {
        console.log("Fallback!")
        fallback()
      }
    }
  }

  const loaderUp = document.getElementById("___loaderUp")
  const loaderDown = document.getElementById("___loaderDown")
  onLoad(
    () => {
      loaderUp.style.transform = "translateY(-100%)"
      loaderUp.style.transition = "all 1s ease-in-out"
      loaderUp.style.opacity = "0.5"
      loaderDown.style.transform = "translateY(100%)"
      loaderDown.style.transition = "all 1s ease-in-out"
      loaderDown.style.opacity = "0.5"
      document.getElementById("___gatsby").style.opacity = 1
    },
    () => {
      loaderUp.style.transform = "translateY(-100%)"
      loaderUp.style.transition = "all 1s ease-in-out"
      loaderUp.style.opacity = "0.5"
      loaderDown.style.transform = "translateY(100%)"
      loaderDown.style.transition = "all 1s ease-in-out"
      loaderDown.style.opacity = "0.5"
      document.getElementById("___gatsby").style.opacity = 1
    }
  )
}
